import {
  BRIEF_TYPE_NEW_SAMPLE_VIDEO,
  BRIEF_TYPE_ON_PLAN_VIDEO,
  BRIEF_TYPE_SAMPLE_VIDEO,
} from "./common";

export const MEMBERS = ["Thảo Thảo", "Nhật Minh"];
export const DESIGNER_MEMBERS = ["Phương Duy"];
export const GROUP_WORKS = ["BD1", "BD2", "BD3"];
export const BD_TEAMS = ["BD1", "BD2", "BD3", "AMZ"];
export const POD_TEAMS = ["BD1", "BD2", "BD3"];
export const AMZ_TEAM = "AMZ";
export const UNIQUE_TEAMS = ["U1", "U2", "U3", "U4", "U5", "U6", "Optimize"];
export const DEPARTMENT_TEAMS = ["Product", "Sourcing"];

export const OPTIMIZED_LISTING_BRIEF_TYPE = "Optimized Listing";
export const OPTIMIZED_ADS_BRIEF_TYPE = "Optimized Ads";
export const OPTIMIZED_FULL_FLOW_BRIEF_TYPE = "Optimized Full Flow";
export const OPTIMIZED_SCALE_PL_BRIEF_TYPE = "Optimize Scale PL";

export const BRIEF_TYPES = [
  "Scale - Product Line",
  "Scale - Clipart",
  "Scale - Niche",
  "New - Phủ Market",
  "Scale - Design",
  "New - Mix Match",
  "Optimized Listing",
  "Optimized Ads",
  "Optimized Full Flow",
  "Market Trend",
  OPTIMIZED_SCALE_PL_BRIEF_TYPE,
];
export const CHOOSE_BRIEF_TYPES = [
  "Scale - Product Line",
  "Scale - Clipart",
  "Scale - Design",
  "Scale - Niche",
  "New - Phủ Market",
  "New - Mix Match",
  "Market Trend",
  "Optimized Listing",
  "Optimized Ads",
  "Optimized Full Flow",
  OPTIMIZED_SCALE_PL_BRIEF_TYPE,
  BRIEF_TYPE_SAMPLE_VIDEO,
  BRIEF_TYPE_NEW_SAMPLE_VIDEO,
  BRIEF_TYPE_ON_PLAN_VIDEO,
];
export const BRIEF_TYPE_SCALE_FROM_POD = "Tận dụng từ POD";
export const BRIEF_VALUES = ["Small", "Medium", "Big", "Super Big"];
export const RND_SIZES = ["Small", "Small+", "Medium", "Big"];
export const UID_CARD_SIZES = [
  {
    label: "Small",
    value: 1,
  },
  {
    label: "Medium",
    value: 2,
  },
  {
    label: "Big",
    value: 3,
  },
  {
    label: "Super Big",
    value: 4,
  },
  {
    label: "Small+",
    value: 1.5,
  },
];
export const LAYOUT_TYPES = [
  "Product Base - Chung Layout",
  "Product Base - Khác Layout",
];
export const KEEP_CLIPARTS = ["Giữ ClipArt", "Đổi ClipArt"];
export const COLLECTIONS = [
  {
    name: "Drinkware",
    productLines: ["PL1", "PL2", "PL3"],
  },
  {
    name: "Apparel",
    productLines: ["PL3", "PL4", "PL5"],
  },
  {
    name: "Drinkware 2",
    productLines: ["PL1", "PL3", "PL6"],
  },
  {
    name: "Apparel 2",
    productLines: ["PL2", "PL4", "PL7", "PL8"],
  },
];

export const PROJECTS = {
  POD: {
    LABEL: "POD",
    VALUE: "POD",
  },
  AMZ: {
    LABEL: "AMZ",
    VALUE: "AMZ",
  },
  POLITICS: {
    LABEL: "Politics",
    VALUE: "Politics",
  },
};
