import useTable from "./useTable";
import classes from "./index.module.css";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { Flex, Group, Radio, SegmentedControl } from "@mantine/core";
import AppMultiSelect from "../../../components/CustomMultiselect";
import {
  calculateSummaryRowMasterReport,
  CURRENT_MONTH,
  getComparisonColor,
  getComparisonColorV2,
  getMonthArray,
} from "../../../utils";
import { concat, map } from "lodash";
import { UNIQUE_TEAMS } from "../../../constant";

const DATES = {
  THIRTY_DAYS: {
    LABEL: "30 days",
    VALUE: 31,
  },
  SEVEN_DAYS: {
    LABEL: "7 days",
    VALUE: 7,
  },
  THREE_DAYS: {
    LABEL: "3 days",
    VALUE: 3,
  },
};

const MasterReportTable = ({ query, setQuery, endPoint, elements }) => {
  const {
    data,
    loading,
    handleChangeDateRange,
    handleChangeMonth,
    handleChangeTeams,
    onClearTeams,
  } = useTable({
    query,
    setQuery,
    endPoint,
  });
  const summaryData = calculateSummaryRowMasterReport(data || [], query?.month);

  const table = useMantineReactTable({
    mantineTableHeadCellProps: { className: classes["head-cells"] },
    mantineTableProps: {
      stripedColor: "#ffffff",
      highlightOnHoverColor: "#ffffff",
    },
    columns: [
      {
        accessorKey: "formattedDate",
        header: "Date",
        size: 90,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: {
          className: classes["body-cells"],
          style: {
            backgroundColor: "#f2f3f5",
            fontWeight: "bold",
          },
        },
      },
      {
        accessorKey: "doneCard",
        header: "Performance (Card Done)",
        mantineTableBodyCellProps: {
          className: classes["body-cells"],
        },

        columns: [
          {
            accessorKey: "rndDoneCard",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: {
              className: classes["body-cells"],
            },
            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <span>RnD</span>
                  <span>Brief</span>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const color = getComparisonColor(
                "rndDoneCard",
                row?.original?.rndDoneCard,
                elements
              );
              return (
                <span style={{ color }}>{row?.original?.rndDoneCard}</span>
              );
            },
          },
          {
            accessorKey: "designDoneCard",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: {
              className: classes["body-cells"],
            },
            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <span>Design</span>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const color = getComparisonColor(
                "designDoneCard",
                row?.original?.designDoneCard,
                elements
              );
              return (
                <span style={{ color }}>{row?.original?.designDoneCard}</span>
              );
            },
          },
          {
            accessorKey: "epmDoneCard",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: {
              className: classes["body-cells"],
            },
            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <span>Listing</span>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const color = getComparisonColor(
                "epmDoneCard",
                row?.original?.epmDoneCard,
                elements
              );
              return (
                <span style={{ color }}>{row?.original?.epmDoneCard}</span>
              );
            },
          },
          {
            accessorKey: "sampleDoneCard",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: {
              className: classes["body-cells"],
            },
            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <span>Sample</span>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const color = getComparisonColor(
                "sampleDoneCard",
                row?.original?.sampleDoneCard,
                elements
              );
              return (
                <span style={{ color }}>{row?.original?.sampleDoneCard}</span>
              );
            },
          },
          {
            accessorKey: "videoDoneCard",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: {
              className: classes["body-cells"],
            },
            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <span>Video</span>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const color = getComparisonColor(
                "videoDoneCard",
                row?.original?.videoDoneCard,
                elements
              );
              return (
                <span style={{ color }}>{row?.original?.videoDoneCard}</span>
              );
            },
          },
          {
            accessorKey: "totalSkuDone",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: {
              className: classes["body-cells"],
            },
            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <span>Launching</span>
                  <span>(SKU)</span>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const color = getComparisonColor(
                "totalSkuDone",
                row?.original?.totalSkuDone,
                elements
              );
              return (
                <span style={{ color }}>{row?.original?.totalSkuDone}</span>
              );
            },
          },
          {
            accessorKey: "totalDoneOptimize",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: {
              className: classes["body-cells"],
            },
            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <span>Optimize</span>
                  <span>(TB done)</span>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const colorDoneOptimize = getComparisonColor(
                "totalDoneOptimize",
                row?.original?.optimize?.totalDoneOptimize,
                elements
              );
              const averageTimeDoneColor = getComparisonColor(
                "optimizeAverageTimeDone",
                row?.original?.optimize?.averageTimeDone,
                elements
              );
              return (
                <Flex justify="space-between">
                  <span style={{ color: colorDoneOptimize }}>
                    {row?.original?.optimize?.totalDoneOptimize}
                  </span>
                  <span style={{ color: averageTimeDoneColor }}>
                    {row?.original?.optimize?.averageTimeDone}{" "}
                    {row?.original?.optimize?.averageTimeDone > 1
                      ? "days"
                      : "day"}
                  </span>
                </Flex>
              );
            },
          },
        ],
      },
    ],
    data: concat(data, summaryData),
    editDisplayMode: "cell",
    enablePagination: false,
    enableRowSelection: false,
    enableFilters: false,
    enableColumnActions: false,
    enableSorting: false,
    state: {
      loading,
    },
    getRowId: (row) => row.id || row.formattedDate,
    renderTopToolbar: () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 5px",
            gap: "10px",
            flexWrap: "wrap-reverse",
            marginLeft: "10px",
          }}
        >
          <Group>
            <Radio.Group value={query?.days} onChange={handleChangeDateRange}>
              <Group>
                <Radio
                  value={DATES.THIRTY_DAYS.VALUE}
                  label={DATES.THIRTY_DAYS.LABEL}
                />
                <Radio
                  value={DATES.SEVEN_DAYS.VALUE}
                  label={DATES.SEVEN_DAYS.LABEL}
                  disabled={query?.month !== CURRENT_MONTH}
                />

                <Radio
                  value={DATES.THREE_DAYS.VALUE}
                  label={DATES.THREE_DAYS.LABEL}
                  disabled={query?.month !== CURRENT_MONTH}
                />
              </Group>
            </Radio.Group>
            <AppMultiSelect
              placeholder={"Select team"}
              defaultValue={query?.teams}
              options={map(UNIQUE_TEAMS, (x) => {
                return {
                  value: x,
                  label: x,
                };
              })}
              onChange={(value) => {
                handleChangeTeams(value);
              }}
              onClear={() => {
                onClearTeams();
              }}
            />
          </Group>
          <Group>
            <SegmentedControl
              value={query?.month?.toString()}
              onChange={handleChangeMonth}
              data={getMonthArray().map((x) => {
                return {
                  value: `${x}`,
                  label: `T${x}`,
                };
              })}
            />
          </Group>
        </div>
      );
    },
    mantineTableBodyCellProps: ({ row, table, cell }) => ({
      onDoubleClick: () => {
        if (
          cell &&
          (cell.column.id === "linkFileIn" ||
            cell.column.id === "value" ||
            cell.column.id === "rndTeam")
        ) {
          table.setEditingCell(cell);
        }
      },
      onBlur: () => {
        table.setEditingCell(null);
      },
      style: {
        verticalAlign: "middle",
        textAlign: "center",
        "--mrt-row-hover-background-color": "#ffffff",
        borderRight: "1px solid #d1d6e3",
      },
    }),
    mantineTableHeadProps: {
      className: classes["mantine-Table-thead"],
    },
    enableStickyHeader: true,
  });

  return (
    <div>
      <MantineReactTable table={table} />
    </div>
  );
};

export default MasterReportTable;
