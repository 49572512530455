import React, { useEffect, useState } from "react";
import { Tabs } from "@mantine/core";
import styles from "./Playground.module.sass";
import Card from "../../components/Card";
import CreateTaskTab from "./Tabs/CreateTaskTab";
import HistoryTaskTab from "./Tabs/HistoryTaskTab";
import { usePlayground } from "./hooks/usePlayground.hook";

const Playground = () => {
  const [activeTab, setActiveTab] = useState("create");
  const { fetchTasks, tasks, loading } = usePlayground();

  useEffect(() => {
    fetchTasks();
  }, []);

  const handleTabChange = (value) => {
    setActiveTab(value);
    if (value === "history") {
      fetchTasks();
    }
  };

  return (
    <Card title="Playground" classTitle="title-green">
      <div className={styles.playground}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          className={styles.tabs}
        >
          <Tabs.List>
            <Tabs.Tab value="create">Create Task</Tabs.Tab>
            <Tabs.Tab value="history">History</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="create">
            <CreateTaskTab />
          </Tabs.Panel>

          <Tabs.Panel value="history">
            <HistoryTaskTab tasks={tasks} loading={loading} />
          </Tabs.Panel>
        </Tabs>
      </div>
    </Card>
  );
};

// Export the component
export default Playground;
