import cn from "classnames";
import { useEffect, useState } from "react";
import Card from "../../components/Card";
import styles from "./CommonReport.module.sass";
import Table from "./Table";
import { CURRENT_MONTH } from "../../utils";
import { isEmpty, keys, map } from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import { settingServices } from "../../services/settings";
import { Button, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import TableMetricCondition from "../../components/TableMetricCondition";
import { UNIQUE_TEAMS } from "../../constant";

const chooseView = (view) => {
  switch (view) {
    case "design":
      return {
        endPoint: "/briefs/report-design",
        doneHeader: "DS Done",
        accessorKey: "totalDesignerDoneBriefs",
      };
    case "epm":
      return {
        endPoint: "/briefs/report-epm",
        doneHeader: "EPM Done",
        accessorKey: "totalEpmDoneBriefs",
      };
    case "sample":
      return {
        endPoint: "/briefs/report-sample",
        doneHeader: "Sample Done",
        accessorKey: "totalSampleDoneBriefs",
        accessorKeySkuDone: "totalSampleSkuDone",
      };
    case "video":
      return {
        endPoint: "/briefs/report-video",
        doneHeader: "Video Done",
        accessorKey: "totalVideoDoneBriefs",
        accessorKeySkuDone: "totalVideoSkuDone",
      };
    default:
      return {
        endPoint: "/briefs/report-design",
        doneHeader: "DS Done",
        accessorKey: "totalDesignerDoneBriefs",
      };
  }
};

const CommonReport = ({
  title = "Common Report",
  view = "design",
  settingName,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [query, setQuery] = useState({
    days: 31,
    teams: map(UNIQUE_TEAMS, (x) => {
      return {
        value: x,
        label: x,
      };
    }),
    month: CURRENT_MONTH,
  });

  const [selectedView, setSelectedView] = useState({});

  useEffect(() => {
    const { endPoint, doneHeader, accessorKey, accessorKeySkuDone } =
      chooseView(view);
    setSelectedView({ endPoint, doneHeader, accessorKey, accessorKeySkuDone });
  }, []);

  useEffect(() => {
    const { endPoint, doneHeader, accessorKey, accessorKeySkuDone } =
      chooseView(view);
    setSelectedView({ endPoint, doneHeader, accessorKey, accessorKeySkuDone });
    const defaultQuery = {
      days: 31,
      teams: map(UNIQUE_TEAMS, (x) => {
        return {
          value: x,
          label: x,
        };
      }),
      month: CURRENT_MONTH,
    };
    setQuery(defaultQuery);
  }, [location, navigate]);

  const [settings, setSettings] = useState({});
  const [elements, setElements] = useState([]);
  const fetchDefaultSetting = async () => {
    const settingsResponse = await settingServices.fetchSetting({
      identifier: settingName,
    });
    if (settingsResponse) {
      setSettings(settingsResponse);
      const refactoredElements = map(
        keys(settingsResponse?.data?.attribute),
        (key) => {
          return {
            metric: key,
            label: settingsResponse?.data?.attribute?.[key].label,
            goodOperator:
              settingsResponse?.data?.attribute?.[key]?.good?.operator,
            goodValue: settingsResponse?.data?.attribute?.[key]?.good?.value,
            warningOperator:
              settingsResponse?.data?.attribute?.[key]?.warning?.operator,
            warningValue:
              settingsResponse?.data?.attribute?.[key]?.warning?.value,
          };
        }
      );
      setElements(refactoredElements);
    }
  };
  useState(() => {
    fetchDefaultSetting();
  }, []);
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <div>
      <Card
        className={styles.card}
        title={title}
        classTitle={cn("title-purple", styles.title)}
        classCardHead={cn(styles.head)}
        head={<Button onClick={open}>Set Benchmark</Button>}
      >
        {!isEmpty(selectedView) && (
          <Table
            query={query}
            setQuery={setQuery}
            endPoint={selectedView.endPoint}
            doneHeader={selectedView.doneHeader}
            selectedView={selectedView}
            view={view}
            elements={elements}
          />
        )}
      </Card>
      <Modal
        opened={opened}
        onClose={() => {
          fetchDefaultSetting();
          close();
        }}
        transitionProps={{ transition: "fade", duration: 200 }}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        radius="md"
        // size="lg"
        title="Set Benchmark"
        styles={{
          title: {
            fontSize: 18,
            fontWeight: 700,
          },
        }}
        size="1000px"
      >
        <TableMetricCondition
          settings={settings}
          elements={elements}
          setElements={setElements}
          view={settingName}
        />
      </Modal>
    </div>
  );
};

export default CommonReport;
