import React, { useState, useMemo, useEffect } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import {
  Button,
  Flex,
  Grid,
  Menu,
  Modal,
  Pagination,
  rem,
  Text,
  TextInput,
} from "@mantine/core";
import {
  IconDots,
  IconTrash,
  IconPlus,
  IconSearch,
  IconFilterOff,
} from "@tabler/icons-react";
import classes from "./Direction.module.css";
import { useDisclosure } from "@mantine/hooks";
import cn from "classnames";
import styles from "./Direction.module.sass";
import Card from "../../components/Card";
import moment from "moment-timezone";
import { useForm } from "react-hook-form";

import { userServices } from "../../services/users";
import { showNotification } from "../../utils/index";
import { map, omit } from "lodash";
import { rndServices } from "../../services/rnd";

const CreateDirection = ({ closeModal, setTriggerFetchDirections }) => {
  const [loadingCreateDirection, setLoadingCreateDirection] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const onSubmit = async (data) => {
    setLoadingCreateDirection(true);
    const payload = {
      ...data,
    };
    const createDirectionResponse = await rndServices.createDirection(payload);
    if (createDirectionResponse) {
      showNotification("Thành công", "Tạo direction thành công", "green");
      closeModal();
    }
    setLoadingCreateDirection(false);
    setTriggerFetchDirections(true);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid>
        <Grid.Col
          span={12}
          style={{
            display: "flex",
            gap: "20px",
          }}
          styles={{
            root: {
              width: "100%",
            },
          }}
        >
          <TextInput
            required
            withAsterisk
            name="name"
            label="Name"
            wrapperProps={{
              width: "100%",
            }}
            placeholder="Valentine"
            styles={{
              label: {
                marginBottom: "10px",
                fontWeight: 500,
                fontSize: "0.875rem",
                lineHeight: "1.57143",
                letterSpacing: "0em",
                color: "rgb(25, 25, 25)",
              },
              root: {
                width: "100%",
              },
              input: {
                width: "100%",
              },
              wrapper: {
                width: "100%",
              },
              inputWrapper: {
                width: "100%",
              },
            }}
            {...register("name", {
              required: "Trường này là bắt buộc",
            })}
            error={errors.name ? errors.name.message : null}
          />
        </Grid.Col>
        <Grid.Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            color="rgb(63, 89, 228)"
            w="100%"
            type="submit"
            loading={loadingCreateDirection}
          >
            Create
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
};

const UpdateDirection = ({
  closeModal,
  direction,
  setTriggerFetchDirections,
}) => {
  const [loadingUpdateUser, setLoadingUpdateDirection] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: direction?.name,
    },
  });

  const onSubmit = async (data) => {
    setLoadingUpdateDirection(true);
    const payload = {
      ...data,
    };
    const updateDirectionResponse = await rndServices.updateDirection({
      uid: direction?.uid,
      data: {
        ...direction,
        ...payload,
      },
    });
    if (updateDirectionResponse) {
      showNotification("Thành công", "Cập nhật direction thành công", "green");
      setTriggerFetchDirections(true);
      closeModal();
    }
    setLoadingUpdateDirection(false);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid>
        <Grid.Col
          span={12}
          style={{
            display: "flex",
            gap: "20px",
          }}
        >
          <TextInput
            required
            withAsterisk
            name="name"
            label="Name"
            placeholder="John Doe"
            styles={{
              label: {
                marginBottom: "10px",
                fontWeight: 500,
                fontSize: "0.875rem",
                lineHeight: "1.57143",
                letterSpacing: "0em",
                color: "rgb(25, 25, 25)",
              },
            }}
            {...register("name", { required: "Trường này là bắt buộc" })}
            error={errors.name ? errors.name.message : null}
          />
        </Grid.Col>
        <Grid.Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            color="rgb(63, 89, 228)"
            w="100%"
            type="submit"
            loading={loadingUpdateUser}
          >
            Update
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
};

const ACTIONS = {
  VIEW_DETAILS: "VIEW_DETAILS",
  DELETE_DIRECTION: "Delete Direction",
  CREATE_DIRECTION: "Create Direction",
  UPDATE_DIRECTION: "Update Direction",
};

const DirectionScreen = () => {
  const [loadingFetchDirections, setLoadingFetchDirections] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [query, setQuery] = useState({});
  const [directions, setDirections] = useState([]);
  const [selectedDirection, setSelectedDirection] = useState(null);
  const [triggerFetchDirections, setTriggerFetchDirections] = useState(false);
  const [action, setAction] = useState(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
  });
  const fetchDirections = async (page = 1) => {
    setLoadingFetchDirections(true);
    const response = await rndServices.fetchDirections({
      page,
      limit: 20,
      query: query,
    });
    if (response) {
      const { data, metadata } = response;
      setPagination(metadata);
      setDirections(map(data, (x, index) => ({ ...x, id: index + 1 })));
    } else {
      setDirections([]);
      setPagination({ currentPage: 1, totalPages: 1 });
    }
    setTriggerFetchDirections(false);
    setLoadingFetchDirections(false);
  };
  const handleDeleteDirection = async (uid) => {
    const response = await rndServices.deleteDirection(uid);
    if (response) {
      showNotification("Thành công", "Xóa direction thành công", "green");
      setTriggerFetchDirections(true);
      close();
    }
  };

  const handlePageChange = (page) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        mantineTableHeadCellProps: {
          align: "right",
        },
        size: 50,
        header: "No",
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 130,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) => {
          const { name } = row.original;
          return (
            <div
              onClick={() => {
                setSelectedDirection(row.original);
                setAction(ACTIONS.UPDATE_DIRECTION);
                open();
              }}
            >
              {name}
            </div>
          );
        },
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        accessorFn: (row) =>
          moment(row?.createdAt).tz("Asia/Ho_Chi_Minh").format("DD/MM/YYYY"),
        size: 50,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
      },
      {
        accessorKey: "remove",
        header: "Action",
        enableSorting: false,
        size: 50,
        mantineTableHeadCellProps: { className: classes["remove"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableEditing: false,
        Cell: ({ row }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  variant="filled"
                  color="#ffffff"
                  size="sx"
                  onClick={() => {}}
                >
                  <IconDots color="rgb(25, 25, 25)" />
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>Danger zone</Menu.Label>
                <Menu.Item
                  color="red"
                  leftSection={
                    <IconTrash style={{ width: rem(14), height: rem(14) }} />
                  }
                  onClick={() => {
                    setSelectedDirection(row.original);
                    setAction(ACTIONS.DELETE_DIRECTION);
                    open();
                  }}
                >
                  Delete Direction
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        ),
      },
    ],
    [directions]
  );

  const [keyword, setKeyword] = useState("");
  const table = useMantineReactTable({
    columns,
    data: directions,
    editDisplayMode: "cell",
    enableEditing: true,
    enablePagination: false,
    getRowId: (row) => row.id,
    enableRowSelection: false,
    enableFilters: false,
    enableColumnActions: false,
    mantinePaperProps: {
      style: { "--mrt-striped-row-background-color": "#eff0f1" },
    },
    mantineTableHeadCellProps: { className: classes["head-cells"] },
    mantineTableProps: { striped: "even" },
    enableDensityToggle: false,
    state: {
      showProgressBars: loadingFetchDirections,
    },
    renderTopToolbar: () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 5px",
            gap: "10px",
            flexWrap: "wrap-reverse",
            position: "sticky",
            top: 0,
            right: 0,
            zindex: 10,
          }}
        >
          <Flex
            style={{
              gap: "8px",
              padding: "10px",
              borderRadius: "10px",
              backgroundColor: "#EFF0F1",
              flexWrap: "wrap",
            }}
          >
            <Button
              color="rgb(52, 73, 186)"
              leftSection={
                <IconPlus style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => {
                setAction(ACTIONS.CREATE_DIRECTION);
                open();
              }}
            >
              Create direction
            </Button>
            <TextInput
              placeholder="Keyword"
              size="sm"
              width="200px"
              leftSection={
                <span
                  onClick={() => {
                    setQuery({ ...query, keyword });
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <IconSearch size={16} />
                </span>
              }
              styles={{
                input: {
                  width: "150px",
                },
              }}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setQuery({ ...query, name: keyword });
                }
              }}
            />
            <Button
              onClick={() => {
                setQuery({
                  keyword: null,
                  team: null,
                  position: null,
                  role: null,
                  positionValue: null,
                });
                setKeyword("");
              }}
            >
              <IconFilterOff />
            </Button>
          </Flex>
        </div>
      );
    },
    mantineTableBodyCellProps: ({ cell }) => ({
      className: classes["body-cells"],
      onClick: () => {
        if (cell && cell.column.id === "priority") {
          return;
        }
      },
      sx: {
        cursor: "pointer", //you might want to change the cursor too when adding an onClick
      },
    }),
  });

  useEffect(() => {
    fetchDirections(pagination.currentPage);
  }, [triggerFetchDirections, pagination.currentPage, query]);

  return (
    <Card
      className={cn(styles.card, styles.clipArtCard)}
      title="Directions"
      classTitle="title-green"
      classCardHead={styles.classCardHead}
      classSpanTitle={styles.classScaleSpanTitle}
    >
      <MantineReactTable table={table} />
      <Pagination
        total={pagination.totalPages}
        page={pagination.currentPage}
        onChange={handlePageChange}
        color="pink"
        size="md"
        style={{ marginTop: "20px", marginLeft: "auto" }}
      />
      <Modal
        opened={opened}
        onClose={close}
        transitionProps={{ transition: "fade", duration: 200 }}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        radius="md"
        title={action}
      >
        {action === ACTIONS.DELETE_DIRECTION && (
          <Grid>
            <Grid.Col span={12}>
              <Text
                style={{
                  fontWeight: 500,
                  fontSize: "0.875rem",
                  lineHeight: "1.57143",
                  letterSpacing: "0em",
                  color: "rgb(25, 25, 25)",
                }}
              >
                Are you sure you want to delete this direction?
              </Text>
            </Grid.Col>
            <Grid.Col span={12}>
              <Button
                color="red"
                w="100%"
                onClick={() => {
                  handleDeleteDirection(selectedDirection?.uid);
                }}
              >
                Delete Direction
              </Button>
            </Grid.Col>
          </Grid>
        )}

        {action === ACTIONS.CREATE_DIRECTION && (
          <CreateDirection
            closeModal={close}
            setTriggerFetchDirections={setTriggerFetchDirections}
          />
        )}
        {action === ACTIONS.UPDATE_DIRECTION && (
          <UpdateDirection
            closeModal={close}
            direction={selectedDirection}
            setTriggerFetchDirections={setTriggerFetchDirections}
          />
        )}
      </Modal>
    </Card>
  );
};

export default DirectionScreen;
