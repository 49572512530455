import {
  Table,
  ScrollArea,
  Group,
  Text,
  Image,
  ActionIcon,
  Button,
  Grid,
  Autocomplete,
  Select,
} from "@mantine/core";
import {
  concat,
  find,
  includes,
  isEmpty,
  map,
  trim,
  uniq,
  uniqBy,
} from "lodash";
import { IconCheck, IconTrash } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { dashboardServices, rndServices } from "../../../services";
import { BRIEF_VALUES, POD_TEAMS, UNIQUE_TEAMS } from "../../../constant";
import {
  CONVERT_NUMBER_TO_STATUS,
  CONVERT_STATUS_TO_NUMBER,
} from "../../../utils";

const SearchSKU = ({ close }) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [selectValue, setSelectValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState(null);
  const [value, setValue] = useState(null);

  useEffect(() => {
    handleSearch();
  }, [searchValue]);

  const handleSearch = async () => {
    try {
      const response = await rndServices.fetchSKUs({
        limit: 20,
        page: 1,
        ...(searchValue && {
          query: {
            keyword: searchValue,
          },
        }),
        fields: "uid,sku,productLine,productId,imageLink,designLink",
      });
      const { data } = response;

      setSearchResult(
        map(data, (x) => ({
          ...x,
          imageLink: x?.productInfo?.imageSrc,
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  const createBatchRequest = async () => {
    setLoading(true);
    const payloads = map(selectValue, (x) => ({
      ...(team && { team }),
      ...(value && { value }),
      sku: x?.sku,
      imageLink: x?.imageLink,
      productId: x?.productId,
      markOptimized: 1,
    }));
    const response = await dashboardServices.createMarkOptimizedRequest({
      payloads,
    });
    setLoading(false);
    if (response) {
      close();
    }
  };

  return (
    <Grid>
      <Grid.Col span={4}>
        <Autocomplete
          placeholder="Search SKU"
          data={uniq(map(searchResult, "sku"))}
          withScrollArea={true}
          defaultDropdownOpened={false}
          maxDropdownHeight={100}
          value={searchValue}
          onChange={(value) => {
            setSearchValue(value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              const value = trim(event.target.value);
              if (value) {
                setSelectValue((prev) =>
                  uniqBy([find(searchResult, { sku: value }), ...prev], "sku")
                );
              }
            }
          }}
          onOptionSubmit={(value) => {
            setSelectValue((prev) =>
              uniqBy([find(searchResult, { sku: value }), ...prev], "sku")
            );
          }}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <Select
          placeholder="Team"
          data={concat(UNIQUE_TEAMS, POD_TEAMS)}
          required
          value={team}
          maxDropdownHeight={400}
          onChange={(value) => setTeam(value)}
          renderOption={({ option, checked }) => (
            <Group flex="1" gap="xs">
              <span
                style={{
                  color: includes(POD_TEAMS, option.label) ? "gray" : "",
                }}
              >
                {option.label}
              </span>
              {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
            </Group>
          )}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <Select
          placeholder="Value"
          data={BRIEF_VALUES}
          required
          value={CONVERT_NUMBER_TO_STATUS[value]}
          onChange={(value) => setValue(CONVERT_STATUS_TO_NUMBER[value])}
        />
      </Grid.Col>

      <Grid.Col span={12}>
        {!isEmpty(selectValue) && (
          <PreviewTable data={selectValue} setData={setSelectValue} />
        )}
      </Grid.Col>
      <Grid.Col
        span={12}
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={createBatchRequest} loading={loading}>
          Request
        </Button>
      </Grid.Col>
    </Grid>
  );
};

const PreviewTable = ({ data, setData }) => {
  const headCellStyles = {
    fontWeight: "bold",
    color: "#000000",
    padding: 0,
    backgroundColor: "#e1eaff",
    height: "70px",
    borderRight: "1px solid #d1d6e3",
    textAlign: "center",
    verticalAlign: "middle",
  };

  const rows = map(data, (item) => {
    return (
      <Table.Tr key={item?.id}>
        <Table.Td>
          <Group gap="sm">
            <Image
              src={item?.imageLink || "/images/content/not_found_2.jpg"}
              alt={item?.name}
              width="70px"
              height="70px"
              fit="cover"
            />
            <Text size="sm" fw={500}>
              {item?.sku}
            </Text>
          </Group>
        </Table.Td>
        <Table.Td
          style={{
            verticalAlign: "middle",
          }}
        >
          <Group
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActionIcon
              variant="filled"
              aria-label="Settings"
              color="red"
              onClick={() => {
                setData((prev) => prev.filter((i) => i?.sku !== item?.sku));
              }}
            >
              <IconTrash style={{ width: "70%", height: "70%" }} stroke={1.5} />
            </ActionIcon>
          </Group>
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <ScrollArea>
      <Table miw={500} verticalSpacing="sm">
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={headCellStyles}>SKU</Table.Th>
            <Table.Th style={headCellStyles}>Remove</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </ScrollArea>
  );
};

export default SearchSKU;
