import useTable from "./useTable";
import classes from "./index.module.css";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { Flex, Group, Radio, SegmentedControl } from "@mantine/core";
import AppMultiSelect from "../../../components/CustomMultiselect";
import {
  calculateSummaryRowCommonReport,
  CURRENT_MONTH,
  getComparisonColor,
  getMonthArray,
} from "../../../utils";
import { map } from "lodash";
import { UNIQUE_TEAMS } from "../../../constant";

const DATES = {
  THIRTY_DAYS: {
    LABEL: "30 days",
    VALUE: 31,
  },
  SEVEN_DAYS: {
    LABEL: "7 days",
    VALUE: 7,
  },
  THREE_DAYS: {
    LABEL: "3 days",
    VALUE: 3,
  },
};

const SampleReportTable = ({ query, setQuery, endPoint, elements }) => {
  const {
    data,
    loading,
    handleChangeDateRange,
    handleChangeMonth,
    handleChangeTeams,
    onClearTeams,
  } = useTable({
    query,
    setQuery,
    endPoint,
  });
  const summaryData = calculateSummaryRowCommonReport(
    data?.data || [],
    query?.month,
    "totalSampleDoneBriefs",
    "totalSampleSkuDone"
  );

  const table = useMantineReactTable({
    mantineTableHeadCellProps: { className: classes["head-cells"] },
    mantineTableProps: {
      stripedColor: "#ffffff",
      highlightOnHoverColor: "#ffffff",
    },
    columns: [
      {
        accessorKey: "performance",
        header: "Performance",
        columns: [
          {
            accessorKey: "formattedDate",
            header: "Date",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: {
              className: classes["body-cells"],
              style: {
                backgroundColor: "#f2f3f5",
                fontWeight: "bold",
              },
            },
          },
          {
            accessorKey: "totalSampleDoneBriefs",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: { className: classes["body-cells"] },
            mantineTableHeadCellProps: { className: classes["edit-header"] },
            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <span>Sample Done</span>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const color = getComparisonColor(
                "totalSampleDoneBriefs",
                row?.original?.totalSampleDoneBriefs,
                elements
              );
              return (
                <span
                  style={{
                    color: color,
                  }}
                >
                  {row?.original?.totalSampleDoneBriefs}
                </span>
              );
            },
          },
          {
            accessorKey: "totalSampleSkuDone",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: {
              className: classes["body-cells"],
            },
            mantineTableHeadCellProps: {
              className: classes["edit-header"],
            },
            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <span>SKU Done</span>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const color = getComparisonColor(
                "totalSampleSkuDone",
                row?.original?.totalSampleSkuDone,
                elements
              );
              return (
                <span
                  style={{
                    color: color,
                  }}
                >
                  {row?.original?.totalSampleSkuDone}
                </span>
              );
            },
          },
          {
            accessorKey: "timeAverageToDone",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: { className: classes["body-cells"] },
            mantineTableHeadCellProps: { className: classes["default-header"] },
            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <span>Time TB</span>
                  <span>đề bài</span>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const color = getComparisonColor(
                "timeAverageToDone",
                row?.original?.timeAverageToDone,
                elements
              );
              return (
                <span
                  style={{
                    color: color,
                  }}
                >
                  {row?.original?.timeAverageToDone}
                </span>
              );
            },
          },
          {
            accessorKey: "timeRealToDone",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: { className: classes["body-cells"] },
            mantineTableHeadCellProps: { className: classes["default-header"] },
            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <span>Time TB</span>
                  <span>thực tế</span>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const color = getComparisonColor(
                "timeRealToDone",
                row?.original?.timeRealToDone,
                elements
              );
              return (
                <span
                  style={{
                    color: color,
                  }}
                >
                  {row?.original?.timeRealToDone}
                </span>
              );
            },
          },
        ],
      },
      {
        accessorKey: "balance",
        header: "Balance (PDCA)",
        columns: [
          {
            accessorKey: "balanceYesterday",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: { className: classes["body-cells"] },
            mantineTableHeadCellProps: { className: classes["default-header"] },
            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <span>Balance</span>
                  <span>hôm trước</span>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const color = getComparisonColor(
                "balanceYesterday",
                row?.original?.balanceYesterday,
                elements
              );
              return (
                <span
                  style={{
                    color: color,
                  }}
                >
                  {row?.original?.balanceYesterday}
                </span>
              );
            },
          },
          {
            accessorKey: "totalBriefs",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: { className: classes["body-cells"] },
            mantineTableHeadCellProps: { className: classes["default-header"] },
            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <span>Card vào</span>
                  <span>(input)</span>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const color = getComparisonColor(
                "totalBriefs",
                row?.original?.totalBriefs,
                elements
              );
              return (
                <span
                  style={{
                    color: color,
                  }}
                >
                  {row?.original?.totalBriefs}
                </span>
              );
            },
          },
          {
            accessorKey: "totalDeletedBriefs",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: { className: classes["body-cells"] },
            mantineTableHeadCellProps: { className: classes["default-header"] },
            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <span>Card xóa</span>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const color = getComparisonColor(
                "totalDeletedBriefs",
                row?.original?.totalDeletedBriefs,
                elements
              );
              return (
                <span
                  style={{
                    color: color,
                  }}
                >
                  {row?.original?.totalDeletedBriefs}
                </span>
              );
            },
          },
          {
            accessorKey: "balance",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: { className: classes["body-cells"] },
            mantineTableHeadCellProps: { className: classes["edit-header"] },
            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <span>Balance</span>
                  <span>hôm nay</span>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const color = getComparisonColor(
                "balance",
                row?.original?.balance,
                elements
              );
              return (
                <span
                  style={{
                    color: color,
                  }}
                >
                  {row?.original?.balance}
                </span>
              );
            },
          },
          {
            accessorKey: "daysToDone",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: { className: classes["body-cells"] },
            mantineTableHeadCellProps: { className: classes["edit-header"] },
            Header: () => {
              return (
                <Flex direction="column" align="center">
                  <span>Days</span>
                  <span>to Done</span>
                </Flex>
              );
            },
            Cell: ({ row }) => {
              const color = getComparisonColor(
                "daysToDone",
                row?.original?.daysToDone,
                elements
              );
              return (
                <span
                  style={{
                    color: color,
                  }}
                >
                  {row?.original?.daysToDone}{" "}
                  {row?.original?.daysToDone > 1 ? "days" : "day"}
                </span>
              );
            },
          },
          {
            accessorKey: "totalArchivedBriefs",
            header: "Backlog",
            size: 50,
            enableEditing: false,
            enableSorting: false,
            mantineTableBodyCellProps: { className: classes["body-cells"] },
            mantineTableHeadCellProps: { className: classes["default-header"] },
            Cell: ({ row }) => {
              const color = getComparisonColor(
                "totalArchivedBriefs",
                row?.original?.totalArchivedBriefs,
                elements
              );
              return (
                <span
                  style={{
                    color,
                  }}
                >
                  {row?.original?.totalArchivedBriefs}
                </span>
              );
            },
          },
        ],
      },
    ],
    data: [...(data?.data || []), summaryData],
    editDisplayMode: "cell",
    enablePagination: false,
    enableRowSelection: false,
    enableFilters: false,
    enableColumnActions: false,
    enableSorting: false,
    state: {
      loading,
    },
    getRowId: (row) => row.id || row.formattedDate,
    renderTopToolbar: () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 5px",
            gap: "10px",
            flexWrap: "wrap-reverse",
            marginLeft: "10px",
          }}
        >
          <Group>
            <Radio.Group value={query?.days} onChange={handleChangeDateRange}>
              <Group>
                <Radio
                  value={DATES.THIRTY_DAYS.VALUE}
                  label={DATES.THIRTY_DAYS.LABEL}
                />
                <Radio
                  value={DATES.SEVEN_DAYS.VALUE}
                  label={DATES.SEVEN_DAYS.LABEL}
                  disabled={query?.month !== CURRENT_MONTH}
                />

                <Radio
                  value={DATES.THREE_DAYS.VALUE}
                  label={DATES.THREE_DAYS.LABEL}
                  disabled={query?.month !== CURRENT_MONTH}
                />
              </Group>
            </Radio.Group>
            <AppMultiSelect
              placeholder={"Select team"}
              defaultValue={query?.teams}
              options={map(UNIQUE_TEAMS, (x) => {
                return {
                  value: x,
                  label: x,
                };
              })}
              onChange={(value) => {
                handleChangeTeams(value);
              }}
              onClear={() => {
                onClearTeams();
              }}
            />
          </Group>
          <Group>
            <SegmentedControl
              value={query?.month?.toString()}
              onChange={handleChangeMonth}
              data={getMonthArray().map((x) => {
                return {
                  value: `${x}`,
                  label: `T${x}`,
                };
              })}
            />
          </Group>
        </div>
      );
    },
    mantineTableBodyCellProps: ({ row, table, cell }) => ({
      onDoubleClick: () => {
        if (
          cell &&
          (cell.column.id === "linkFileIn" ||
            cell.column.id === "value" ||
            cell.column.id === "rndTeam")
        ) {
          table.setEditingCell(cell);
        }
      },
      onBlur: () => {
        table.setEditingCell(null);
      },
      style: {
        verticalAlign: "middle",
        textAlign: "center",
        "--mrt-row-hover-background-color": "#ffffff",
      },
    }),
    mantineTableHeadProps: {
      className: classes["mantine-Table-thead"],
    },
    enableStickyHeader: true,
  });

  return (
    <div>
      <MantineReactTable table={table} />
    </div>
  );
};

export default SampleReportTable;
