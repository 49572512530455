import React, { useEffect, useState } from "react";
import styles from "./Designer.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import ProductLine from "../../../components/Designer/Productline";
import ScaleClipart from "../../../components/Designer/ScaleCliparts";
import ScaleNiche from "../../../components/Designer/Niche";
import NewDesign from "../../../components/Designer/NewDesign";
import ScaleDesign from "../../../components/Designer/ScaleDesign";
import ScaleMixMatch from "../../../components/Designer/ScaleMixMatch";
import Optimized from "../../../components/Designer/Optimized";
import ModalEditNoteEPM from "../../../components/Designer/ModalEditNoteEPM";
import Table from "../../../components/Designer/Table";
import { map, toLower } from "lodash";

import { useDisclosure } from "@mantine/hooks";

import { Pagination, Grid, Flex, Switch } from "@mantine/core";
import moment from "moment-timezone";
import { DEFAULT_QUERY } from "./defaultQuery";
import { useQueryParams } from "use-query-params";
import { dashboardServices, rndServices } from "../../../services";
import {
  BRIEF_TYPES,
  OPTIMIZED_ADS_BRIEF_TYPE,
  OPTIMIZED_FULL_FLOW_BRIEF_TYPE,
  OPTIMIZED_LISTING_BRIEF_TYPE,
  OPTIMIZED_SCALE_PL_BRIEF_TYPE,
  STATUS,
} from "../../../constant";
import { showNotification } from "../../../utils/index";

const OPTIMIZED_BRIEF_TYPES = [
  OPTIMIZED_LISTING_BRIEF_TYPE,
  OPTIMIZED_ADS_BRIEF_TYPE,
  OPTIMIZED_FULL_FLOW_BRIEF_TYPE,
  OPTIMIZED_SCALE_PL_BRIEF_TYPE,
];

const OptimizedDesigner = ({ view = "design" }) => {
  const [visible, setVisible] = useState(true);
  const [usersFetched, setUsersFetched] = useState(false);
  const [directionsFetched, setDirectionsFetched] = useState(false);

  const [briefs, setBriefs] = useState([]);
  const [directions, setDirections] = useState([]);

  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
  });
  // Use the custom dynamic query params hook
  const [query, setQuery] = useQueryParams({
    ...DEFAULT_QUERY,
  });
  const [sorting, setSorting] = useState([]);

  const [opened, { open, close }] = useDisclosure(false);
  const [openedNoteForEPM, { open: openNoteForEPM, close: closeNoteForEPM }] =
    useDisclosure(false);
  const [selectedSKU, setSelectedSKU] = useState({});
  const [updateBrief, setUpdateBrief] = useState({});
  const [editingCell, setEditingCell] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [metadata, setMetadata] = useState({});
  const [linkDesign, setLinkDesign] = useState("");
  const [timeSettings, setTimeSettings] = useState([]);

  const [loadingFetchBrief, setLoadingFetchBrief] = useState(false);
  const [loadingUpdateDesignLink, setLoadingUpdateDesignLink] = useState(false);

  const fetchBriefs = async (page = 1) => {
    setLoadingFetchBrief(true);
    const response = await rndServices.fetchBriefs({
      page,
      limit: 30,
      sorting,
      view: "optimize-design",
      ...query,
    });
    const { data, metadata } = response;
    if (data) {
      setBriefs(
        map(data, (x, index) => {
          return {
            ...x,
            id: index + 1,
            date: moment(x.createdAt)
              .tz("Asia/Ho_Chi_Minh")
              .format("DD/MM/YYYY"),
            time: Math.floor(
              moment()
                .tz("Asia/Ho_Chi_Minh")
                .diff(moment(x.createdAt), "hours", true)
            ),
          };
        })
      );
      setPagination(metadata);
      setMetadata(metadata);
    } else {
      setBriefs([]);
    }
    setLoadingFetchBrief(false);
    setTrigger(false);
    setDesignerNote(selectedSKU?.note?.designer || "");
  };
  const fetchUsers = async () => {
    const { data } = await rndServices.getUsers({
      limit: -1,
    });
    setUsers(data);
    setUsersFetched(true);
  };
  const fetchDirections = async () => {
    const response = await rndServices.fetchDirections({
      page: -1,
      limit: -1,
    });
    const { data } = response;
    setDirections(data || []);
    setDirectionsFetched(true);
  };
  const fetchDashboardSettings = async () => {
    const response = await dashboardServices.fetchDashboardsSetting({
      page: -1,
      query: {
        actives: [0, 1],
      },
      limit: -1,
    });
    const { data } = response;
    if (data) {
      setTimeSettings(
        map(data, (time) => ({
          ...time,
          option: toLower(time.option),
        }))
      );
    }
  };
  const [designerNote, setDesignerNote] = useState("");
  const handlePageChange = (page) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    if (usersFetched && directionsFetched) fetchBriefs(pagination.currentPage);
  }, [
    pagination.currentPage,
    query,
    trigger,
    sorting,
    usersFetched,
    directionsFetched,
  ]);

  useEffect(() => {
    if (selectedSKU) {
      if (
        selectedSKU?.briefType === BRIEF_TYPES[3] ||
        selectedSKU?.briefType === BRIEF_TYPES[5] ||
        selectedSKU?.briefType === BRIEF_TYPES[9]
      ) {
        setDesignerNote(
          selectedSKU?.note?.mixMatch ||
            selectedSKU?.attribute?.refDesignMarketNote ||
            selectedSKU?.note?.designer ||
            ""
        );
      } else {
        setDesignerNote(selectedSKU?.note?.designer || "");
      }
    }
  }, [selectedSKU]);

  useEffect(() => {
    fetchUsers();
    fetchDashboardSettings();
    fetchDirections();
  }, []);

  const handleUpdateLinkDesign = async (uid) => {
    setLoadingUpdateDesignLink(true);
    if (!linkDesign) {
      showNotification("Thất bại", "Link Design không được để trống", "red");
      setLoadingUpdateDesignLink(false);
      return;
    }
    if (selectedSKU?.archive === STATUS.ARCHIVED) {
      showNotification(
        "Thất bại",
        "Không thể update link cho brief đã archived",
        "red"
      );
      setLoadingUpdateDesignLink(false);
      return;
    }
    const urlPattern =
      /^(https?:\/\/)((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[\w-]*)?$/i;
    if (!urlPattern.test(linkDesign)) {
      showNotification("Thất bại", "Link Design không hợp lệ", "red");
      setLoadingUpdateDesignLink(false);
      return;
    }
    let realStatus = null;
    switch (selectedSKU?.briefType) {
      case BRIEF_TYPES[6]:
        if (selectedSKU?.status === STATUS.BRIEF_CREATED) {
          realStatus = STATUS.OPTIMIZED_LISTING_DESIGNED;
        }
        break;
      case BRIEF_TYPES[7]:
        if (selectedSKU?.status === STATUS.BRIEF_CREATED) {
          realStatus = STATUS.OPTIMIZED_ADS_DESIGNED;
        }
        break;
      case BRIEF_TYPES[8]:
        if (selectedSKU?.status === STATUS.BRIEF_CREATED) {
          realStatus = STATUS.DESIGNED;
        }
        break;
      default:
        realStatus = null;
        break;
    }
    if (linkDesign) {
      const updateResponse = await rndServices.updateBriefWithView({
        uid,
        data: {
          linkDesign,
          status: STATUS.DESIGNED,
          ...(realStatus && { status: realStatus }),
        },
        view,
      });
      if (updateResponse) {
        showNotification(
          "Thành công",
          "Update Link Design thành công",
          "green"
        );
        await fetchBriefs(pagination.currentPage);
      }
    }
    close();
    setLoadingUpdateDesignLink(false);
  };
  return (
    <>
      <Card
        className={styles.card}
        title="POD DESIGNER OPTIMIZED TASK"
        classTitle={cn("title-purple", styles.title)}
        classCardHead={cn(styles.head, { [styles.hidden]: visible })}
        head={
          <>
            <Flex
              style={{
                gap: "30px",
                padding: "10px",
                borderRadius: "10px",
                backgroundColor: "#EFF0F1",
              }}
              justify="end"
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Số card: {metadata?.totalUndoneBriefs}
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Time to done: {metadata?.totalTimeToDoneAllBriefsV2Round || 0}
                {metadata?.totalTimeToDoneAllBriefsV2Round > 1
                  ? " Days "
                  : " Day "}
              </div>
            </Flex>
          </>
        }
      >
        <Grid
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "start",
          }}
        >
          <Grid.Col span={6}>
            <Flex
              style={{
                gap: "30px",
                padding: "10px",
                borderRadius: "10px",
                backgroundColor: "#EFF0F1",
              }}
              justify="start"
              align="center"
            >
              <Switch
                checked={query?.priority === 2}
                labelPosition="left"
                onChange={() => {
                  setQuery({
                    ...query,
                    priority: query?.priority === 1 ? 2 : 1,
                  });
                }}
                styles={{
                  label: {
                    fontSize: "16px",
                    fontWeight: "bold",
                  },
                }}
                label="Priority View"
              />
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Số card: {metadata?.totalUndoneBriefsWithFilter}
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Time to done:{" "}
                {metadata?.totalTimeToDoneBriefsWithFilterV2Round || 0}
                {metadata?.totalTimeToDoneBriefsWithFilterV2Round > 1
                  ? " Days "
                  : " Day "}
              </div>
            </Flex>
          </Grid.Col>
        </Grid>
        {!opened && (
          <Table
            className={styles.details}
            onClose={() => setVisible(false)}
            briefs={briefs}
            query={query}
            setQuery={setQuery}
            setSelectedSKU={setSelectedSKU}
            openModal={open}
            users={users}
            setUpdateBrief={setUpdateBrief}
            updateBrief={updateBrief}
            setEditingCell={setEditingCell}
            editingCell={editingCell}
            loadingFetchBrief={loadingFetchBrief}
            setLoadingFetchBrief={setLoadingFetchBrief}
            setTrigger={setTrigger}
            setLinkDesign={setLinkDesign}
            setSorting={setSorting}
            sorting={sorting}
            metadata={metadata}
            openNoteForEPM={openNoteForEPM}
            timeSettings={timeSettings}
            view={"design"}
            selectedBriefTypes={OPTIMIZED_BRIEF_TYPES}
            directions={directions}
          />
        )}
      </Card>
      <Pagination
        total={pagination.totalPages}
        page={pagination.currentPage}
        onChange={handlePageChange}
        color="pink"
        size="md"
        style={{ marginTop: "20px", marginLeft: "auto" }}
      />
      {selectedSKU && selectedSKU?.briefType === BRIEF_TYPES[0] && opened && (
        <ProductLine
          opened={opened}
          close={close}
          setSelectedSKU={setSelectedSKU}
          selectedSKU={selectedSKU}
          linkDesign={linkDesign}
          loadingUpdateDesignLink={loadingUpdateDesignLink}
          setLinkDesign={setLinkDesign}
          handleUpdateLinkDesign={handleUpdateLinkDesign}
          setTrigger={setTrigger}
          designerNote={designerNote}
          setDesignerNote={setDesignerNote}
          fetchBriefs={fetchBriefs}
          view={view}
        />
      )}
      {selectedSKU && selectedSKU?.briefType === BRIEF_TYPES[10] && opened && (
        <ProductLine
          opened={opened}
          close={close}
          setSelectedSKU={setSelectedSKU}
          selectedSKU={selectedSKU}
          linkDesign={linkDesign}
          loadingUpdateDesignLink={loadingUpdateDesignLink}
          setLinkDesign={setLinkDesign}
          handleUpdateLinkDesign={handleUpdateLinkDesign}
          setTrigger={setTrigger}
          designerNote={designerNote}
          setDesignerNote={setDesignerNote}
          fetchBriefs={fetchBriefs}
          view={view}
        />
      )}
      {opened && selectedSKU && selectedSKU?.briefType === BRIEF_TYPES[1] && (
        <ScaleClipart
          opened={opened}
          close={close}
          setSelectedSKU={setSelectedSKU}
          selectedSKU={selectedSKU}
          linkDesign={linkDesign}
          loadingUpdateDesignLink={loadingUpdateDesignLink}
          setLinkDesign={setLinkDesign}
          handleUpdateLinkDesign={handleUpdateLinkDesign}
          setTrigger={setTrigger}
          designerNote={designerNote}
          setDesignerNote={setDesignerNote}
          fetchBriefs={fetchBriefs}
          view={view}
        />
      )}
      {opened && selectedSKU && selectedSKU?.briefType === BRIEF_TYPES[2] && (
        <ScaleNiche
          opened={opened}
          close={close}
          setSelectedSKU={setSelectedSKU}
          selectedSKU={selectedSKU}
          linkDesign={linkDesign}
          loadingUpdateDesignLink={loadingUpdateDesignLink}
          setLinkDesign={setLinkDesign}
          handleUpdateLinkDesign={handleUpdateLinkDesign}
          setTrigger={setTrigger}
          designerNote={designerNote}
          setDesignerNote={setDesignerNote}
          fetchBriefs={fetchBriefs}
          view={view}
        />
      )}
      {opened && selectedSKU && selectedSKU?.briefType === BRIEF_TYPES[3] && (
        <NewDesign
          opened={opened}
          close={close}
          setSelectedSKU={setSelectedSKU}
          selectedSKU={selectedSKU}
          linkDesign={linkDesign}
          loadingUpdateDesignLink={loadingUpdateDesignLink}
          setLinkDesign={setLinkDesign}
          handleUpdateLinkDesign={handleUpdateLinkDesign}
          setTrigger={setTrigger}
          designerNote={designerNote}
          setDesignerNote={setDesignerNote}
          fetchBriefs={fetchBriefs}
          view={view}
        />
      )}
      {opened && selectedSKU && selectedSKU?.briefType === BRIEF_TYPES[9] && (
        <NewDesign
          opened={opened}
          close={close}
          setSelectedSKU={setSelectedSKU}
          selectedSKU={selectedSKU}
          linkDesign={linkDesign}
          loadingUpdateDesignLink={loadingUpdateDesignLink}
          setLinkDesign={setLinkDesign}
          handleUpdateLinkDesign={handleUpdateLinkDesign}
          setTrigger={setTrigger}
          designerNote={designerNote}
          setDesignerNote={setDesignerNote}
          fetchBriefs={fetchBriefs}
          view={view}
        />
      )}
      {opened && selectedSKU && selectedSKU?.briefType === BRIEF_TYPES[4] && (
        <ScaleDesign
          opened={opened}
          close={close}
          setSelectedSKU={setSelectedSKU}
          selectedSKU={selectedSKU}
          linkDesign={linkDesign}
          loadingUpdateDesignLink={loadingUpdateDesignLink}
          setLinkDesign={setLinkDesign}
          handleUpdateLinkDesign={handleUpdateLinkDesign}
          setTrigger={setTrigger}
          designerNote={designerNote}
          setDesignerNote={setDesignerNote}
          fetchBriefs={fetchBriefs}
          view={view}
        />
      )}
      {opened && selectedSKU && selectedSKU?.briefType === BRIEF_TYPES[5] && (
        <ScaleMixMatch
          opened={opened}
          close={close}
          setSelectedSKU={setSelectedSKU}
          selectedSKU={selectedSKU}
          linkDesign={linkDesign}
          loadingUpdateDesignLink={loadingUpdateDesignLink}
          setLinkDesign={setLinkDesign}
          handleUpdateLinkDesign={handleUpdateLinkDesign}
          setTrigger={setTrigger}
          designerNote={designerNote}
          setDesignerNote={setDesignerNote}
          fetchBriefs={fetchBriefs}
          view={view}
        />
      )}
      {opened &&
        selectedSKU &&
        (selectedSKU?.briefType === BRIEF_TYPES[6] ||
          selectedSKU?.briefType === BRIEF_TYPES[7] ||
          selectedSKU?.briefType === BRIEF_TYPES[8]) && (
          <Optimized
            opened={opened}
            close={close}
            setSelectedSKU={setSelectedSKU}
            selectedSKU={selectedSKU}
            linkDesign={linkDesign}
            loadingUpdateDesignLink={loadingUpdateDesignLink}
            setLinkDesign={setLinkDesign}
            handleUpdateLinkDesign={handleUpdateLinkDesign}
            setTrigger={setTrigger}
            designerNote={designerNote}
            setDesignerNote={setDesignerNote}
            fetchBriefs={fetchBriefs}
            view={view}
          />
        )}
      {selectedSKU && openedNoteForEPM && (
        <ModalEditNoteEPM
          opened={openedNoteForEPM}
          close={closeNoteForEPM}
          selectedSKU={selectedSKU}
          setTrigger={setTrigger}
          view={view}
        />
      )}
    </>
  );
};

const OptimizedDesignerScreen = () => {
  return <OptimizedDesigner view="design" />;
};

export default OptimizedDesignerScreen;
