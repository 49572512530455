import { showNotification } from "../utils/index";
import apiClient from "./axiosClient";

export const playgroundServices = {
  createTask: async (data) => {
    try {
      const response = await apiClient.post("/kling-ai", data);
      return response.data;
    } catch (error) {
      const message = error.response?.data?.message || "Error fetching tasks";
      showNotification("Thất bại", message, "red");
      console.error(error);
    }
  },
  fetchTasks: async () => {
    try {
      const response = await apiClient.get("/kling-ai");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
