import React, { useMemo, useCallback, useEffect, useState } from "react";
import { Text, Card, Button, Badge, Image, Modal } from "@mantine/core";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { IconPlayerPlay, IconRefresh } from "@tabler/icons-react";
import { usePlayground } from "../hooks/usePlayground.hook";
import styles from "../Playground.module.sass";
import { isEmpty } from "lodash";
import CreateTaskTab from "./CreateTaskTab";
import moment from "moment-timezone";

const STATUS_COLORS = {
  completed: "green",
  failed: "red",
  submitted: "blue",
  processing: "yellow",
};

const HistoryTaskTab = ({ tasks, loading }) => {
  const { createTask } = usePlayground();
  const [data, setData] = useState(tasks);
  const [selectedTask, setSelectedTask] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);

  const handleRowClick = useCallback((row) => {
    setSelectedTask(row.original);
    setModalOpened(true);
  }, []);

  useEffect(() => {
    if (!isEmpty(tasks)) {
      setData(tasks);
    }
  }, [tasks]);

  const handleRegenerate = useCallback(
    async (data) => {
      try {
        await createTask(data);
        setModalOpened(false);
      } catch (error) {
        console.error("Failed to regenerate task:", error);
      }
    },
    [createTask]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "sku",
        header: "SKU",
        size: 100,
        Cell: ({ cell }) => <Text size="sm">{cell.getValue()}</Text>,
      },
      {
        accessorKey: "imageUrl",
        header: "Image",
        size: 100,
        Cell: ({ cell }) => (
          <Image
            src={cell.getValue()}
            alt="preview"
            radius="md"
            width={48}
            height={48}
            fit="cover"
            styles={{
              image: {
                border: "2px solid #eee",
              },
            }}
          />
        ),
        enableSorting: false,
      },
      {
        accessorKey: "prompt",
        header: "Prompt",
        size: 300,
        Cell: ({ cell }) => (
          <Text
            size="sm"
            lineClamp={2}
            style={{ whiteSpace: "normal", wordBreak: "break-word" }}
          >
            {cell.getValue()}
          </Text>
        ),
      },
      {
        accessorKey: "mode",
        header: "Mode",
        size: 80,
        Cell: ({ cell }) => (
          <Badge
            size="sm"
            variant="dot"
            color={cell.getValue() === "pro" ? "indigo" : "gray"}
          >
            {cell.getValue()}
          </Badge>
        ),
      },
      {
        accessorKey: "duration",
        header: "Duration",
        size: 80,
        Cell: ({ cell }) => (
          <Text size="sm" align="center">
            {cell.getValue()}s
          </Text>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 100,
        Cell: ({ cell, row }) => (
          // if is empty result show processing
          <Badge
            color={
              isEmpty(row.original.result)
                ? "yellow"
                : STATUS_COLORS[cell.getValue()]
            }
            size="sm"
          >
            {isEmpty(row.original.result) ? "Processing" : cell.getValue()}
          </Badge>
        ),
      },
      {
        accessorKey: "result",
        header: "Result",
        size: 100,
        Cell: ({ row }) => {
          const result = row.original.result;
          return result?.url ? (
            <Button
              component="a"
              href={result.url}
              target="_blank"
              size="xs"
              variant="light"
              color="blue"
              leftIcon={<IconPlayerPlay size={14} />}
              styles={{
                root: {
                  padding: "4px 8px",
                },
              }}
            >
              View
            </Button>
          ) : null;
        },
        enableSorting: false,
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        size: 100,
        Cell: ({ cell }) => moment(cell.getValue()).format("DD/MM/YYYY HH:mm"),
      },
      {
        accessorKey: "actions",
        header: "Actions",
        size: 100,
        Cell: ({ row }) => (
          <Button
            onClick={() => handleRowClick(row)}
            size="xs"
            color="green"
            disabled={isEmpty(row.original.result)}
            leftIcon={<IconRefresh size={14} />}
            styles={{
              root: {
                padding: "4px 8px",
              },
            }}
          >
            Regenerate
          </Button>
        ),
        enableSorting: false,
      },
    ],
    [handleRowClick]
  );

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnActions: false,
    enablePagination: true,
    enableSorting: true,
    mantineTableProps: {
      striped: true,
      highlightOnHover: true,
      withColumnBorders: false,
    },
    mantinePaginationProps: {
      showRowsPerPage: false,
    },
    paginationDisplayMode: "pages",
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#f8fafc",
        },
      },
    }),
    mantineBottomToolbarProps: {
      top: "-10px",
    },
    mantineTableBodyCellProps: {
      sx: {
        padding: "12px 16px",
        verticalAlign: "middle",
      },
    },
    mantineTableHeadCellProps: {
      sx: {
        backgroundColor: "#f8fafc",
        color: "#64748b",
        fontWeight: 600,
        padding: "12px 16px",
        borderBottom: "2px solid #e2e8f0",
      },
    },
    state: {
      isLoading: loading.fetch,
    },
    initialState: {
      density: "xs",
      pagination: { pageSize: 10, pageIndex: 0 },
      sorting: [{ id: "taskId", desc: true }],
    },
  });

  return (
    <>
      <Card className={styles.formCard} p={0}>
        <MantineReactTable table={table} />
      </Card>

      <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        title="Edit Task"
        size="xl"
        styles={{
          header: {
            padding: "1.5rem",
            marginBottom: 0,
            borderBottom: "1px solid #e5e7eb",
          },
          title: {
            fontSize: "1.1rem",
            fontWeight: 600,
            color: "#1f2937",
          },
          body: {
            padding: 0, // Remove padding to match CreateTaskTab's style
          },
        }}
      >
        {selectedTask && (
          <div className={styles.modalContent}>
            <CreateTaskTab
              initialData={selectedTask}
              onSubmit={handleRegenerate}
              submitLabel="Regenerate"
              loadingLabel="Regenerating..."
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default HistoryTaskTab;
