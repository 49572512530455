import { useState, useCallback, useEffect } from "react";
import { uploadServices } from "../../../services/uploads";
import { playgroundServices } from "../../../services/playground";
import { orderBy } from "lodash";
import { showNotification } from "../../../utils/index";

export const usePlayground = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState({
    fetch: false,
    create: false,
    upload: false,
  });
  const [error, setError] = useState(null);

  // Fetch tasks with useCallback
  const fetchTasks = useCallback(async (params = {}) => {
    try {
      setLoading((prev) => ({ ...prev, fetch: true }));
      setError(null);

      const response = await playgroundServices.fetchTasks(params);

      // sort tasks by createdAt in descending order using lodash
      setTasks(orderBy(response.data, ["createdAt"], ["desc"]));

      return response.data;
    } catch (err) {
      setError(err.response?.data?.message || "Error fetching tasks");
      console.log(err);
    } finally {
      setLoading((prev) => ({ ...prev, fetch: false }));
    }
  }, []);

  // Create task with auto-refresh
  const createTask = async (data) => {
    try {
      setLoading((prev) => ({ ...prev, create: true }));
      setError(null);

      const taskData = {
        sku: data.sku,
        imageUrl: data.imageUrl,
        prompt: data.prompt,
        mode: data.mode,
        duration: data.duration,
        taskType: "imageToVideo",
      };

      const response = await playgroundServices.createTask(taskData);

      // Refresh tasks list after successful creation
      await fetchTasks();

      return response?.data;
    } catch (err) {
      const message = err.response?.data?.message || "Error creating task";
      setError(message);
      showNotification("Thất bại", message, "red");
      console.log(err);
    } finally {
      setLoading((prev) => ({ ...prev, create: false }));
    }
  };

  // Upload image file
  const uploadImage = useCallback(async (file) => {
    try {
      setLoading((prev) => ({ ...prev, upload: true }));

      const response = await uploadServices.upload(file);
      return response.data.url;
    } catch (err) {
      setError(err.response?.data?.message || "Error uploading image");
      console.log(err);
    } finally {
      setLoading((prev) => ({ ...prev, upload: false }));
    }
  }, []);

  // Reset error state
  const clearError = useCallback(() => setError(null), []);

  return {
    tasks,
    loading,
    error,
    createTask,
    fetchTasks,
    uploadImage,
    clearError,
  };
};
