import React, { useState } from "react";
import {
  Button,
  Card,
  Group,
  Select,
  TextInput,
  Text,
  Stack,
  Textarea,
} from "@mantine/core";
import { IconUpload, IconCloudUpload } from "@tabler/icons-react";
import { useForm, Controller } from "react-hook-form";
import styles from "../Playground.module.sass";
import { usePlayground } from "../hooks/usePlayground.hook";

const UPLOAD_TYPE = {
  FILE: "file",
  URL: "url",
};

const CreateTaskTab = ({
  initialData = null,
  onSubmit,
  submitLabel = "Create Task",
  loadingLabel = "Creating...",
}) => {
  const { loading, uploadImage } = usePlayground();
  const [imageFile, setImageFile] = useState(null);
  const [uploadType, setUploadType] = useState(UPLOAD_TYPE.URL);

  const { createTask } = usePlayground();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: initialData || {
      sku: "",
      imageUrl: "",
      prompt: "",
      mode: "pro",
      duration: "5",
    },
  });

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      // Check if the file is an image
      try {
        const uploadedImageUrl = await uploadImage(file);
        setImageFile(uploadedImageUrl); // Store the uploaded image URL
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    } else {
      console.error("Selected file is not an image.");
    }
  };

  const onSubmitForm = async (data) => {
    try {
      if (onSubmit) {
        await onSubmit({
          ...data,
          ...(imageFile &&
            uploadType === UPLOAD_TYPE.FILE && { imageUrl: imageFile }),
        });
      } else {
        await createTask({
          ...data,
          ...(imageFile &&
            uploadType === UPLOAD_TYPE.FILE && { imageUrl: imageFile }),
        });
      }

      reset();
      setImageFile(null);
    } catch (error) {
      console.error("Failed to submit task:", error);
    }
  };

  const urlPattern = /^https:\/\/.+/;

  return (
    <Card shadow="sm" padding="md" className={styles.formCard}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Stack spacing="xs">
          <Controller
            name="sku"
            control={control}
            rules={{ required: "SKU is required" }}
            render={({ field }) => (
              <TextInput
                label="SKU"
                placeholder="Enter SKU"
                size="sm"
                error={errors.sku?.message}
                {...field}
              />
            )}
          />

          <div className={styles.imageUpload}>
            <Group position="apart" mb="xs">
              <Text weight={600} size="xs" color="#4b5563">
                Image Upload
              </Text>
              <Select
                value={uploadType}
                onChange={setUploadType}
                data={[
                  { value: UPLOAD_TYPE.FILE, label: "Upload File" },
                  { value: UPLOAD_TYPE.URL, label: "Image URL" },
                ]}
                styles={{ root: { width: 150 } }}
                size="sm"
              />
            </Group>

            {uploadType === UPLOAD_TYPE.FILE ? (
              <>
                <label className={styles.uploadArea}>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    accept="image/*"
                    style={{ display: "none" }}
                    required
                  />
                  <IconCloudUpload
                    size={32}
                    className={styles.icon}
                    stroke={1.5}
                  />
                  <Text size="sm" className={styles.text}>
                    Choose Image
                  </Text>
                </label>
                {imageFile && (
                  <div className={styles.preview}>
                    <img
                      src={imageFile}
                      alt="Preview"
                      width={100}
                      height={100}
                    />
                  </div>
                )}
              </>
            ) : (
              <Controller
                name="imageUrl"
                control={control}
                rules={{
                  required: "Image URL is required",
                  pattern: {
                    value: urlPattern,
                    message: "URL must start with https://",
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    placeholder="Paste image URL here"
                    size="sm"
                    error={errors.imageUrl?.message}
                    {...field}
                  />
                )}
              />
            )}
          </div>

          <Controller
            name="prompt"
            control={control}
            rules={{ required: "Prompt is required" }}
            render={({ field }) => (
              <Textarea
                label="Prompt"
                placeholder="Enter your prompt here"
                size="sm"
                minRows={3}
                maxRows={5}
                error={errors.prompt?.message}
                {...field}
              />
            )}
          />

          <Group grow spacing="xs">
            <Controller
              name="mode"
              control={control}
              rules={{ required: "Mode is required" }}
              render={({ field }) => (
                <Select
                  label="Mode"
                  placeholder="Select Mode"
                  data={["pro", "std"]}
                  size="sm"
                  error={errors.mode?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="duration"
              control={control}
              rules={{ required: "Duration is required" }}
              render={({ field }) => (
                <Select
                  label="Duration"
                  placeholder="Duration"
                  data={["5", "10"]}
                  size="sm"
                  error={errors.duration?.message}
                  {...field}
                />
              )}
            />
          </Group>

          <Group position="right" mt="md">
            <Button
              type="submit"
              className={styles.submitButton}
              leftIcon={<IconUpload size={16} />}
              loading={loading.create || loading.upload}
              size="sm"
            >
              {loading.create ? loadingLabel : submitLabel}
            </Button>
          </Group>
        </Stack>
      </form>
    </Card>
  );
};

export default CreateTaskTab;
