import React, { useEffect, useState } from "react";
import styles from "./EPM.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Table from "../../../components/EPM/Table";
import ProductBase from "../../../components/EPM/ProductBase";
import Clipart from "../../../components/EPM/Clipart";
import Niche from "../../../components/EPM/Niche";
import NewDesign from "../../../components/EPM/NewDesign";
import ScaleDesign from "../../../components/EPM/ScaleDesign";
import MixMatch from "../../../components/EPM/MixMatch";
import Optimized from "../../../components/EPM/Optimized";
import { map } from "lodash";

import { useDisclosure } from "@mantine/hooks";
import { Pagination, Grid, Flex, Switch } from "@mantine/core";
import moment from "moment-timezone";

import { useQueryParams } from "use-query-params";
import { DEFAULT_QUERY } from "./defaultQuery";
import { rndServices } from "../../../services";
import {
  BRIEF_TYPES,
  OPTIMIZED_ADS_BRIEF_TYPE,
  OPTIMIZED_FULL_FLOW_BRIEF_TYPE,
  OPTIMIZED_LISTING_BRIEF_TYPE,
  OPTIMIZED_SCALE_PL_BRIEF_TYPE,
  STATUS,
} from "../../../constant";
import { showNotification } from "../../../utils/index";
const OPTIMIZED_BRIEF_TYPES = [
  OPTIMIZED_LISTING_BRIEF_TYPE,
  OPTIMIZED_ADS_BRIEF_TYPE,
  OPTIMIZED_FULL_FLOW_BRIEF_TYPE,
  OPTIMIZED_SCALE_PL_BRIEF_TYPE,
];
const OptimizedEpm = ({ view = "epm" }) => {
  const [visible, setVisible] = useState(true);
  const [briefs, setBriefs] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersFetched, setUsersFetched] = useState(false);
  const [directionsFetched, setDirectionsFetched] = useState(false);

  const [directions, setDirections] = useState([]);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
  });
  const [query, setQuery] = useQueryParams({
    ...DEFAULT_QUERY,
  });
  const [sorting, setSorting] = useState([]);
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedSKU, setSelectedSKU] = useState();
  const [updateBrief, setUpdateBrief] = useState({});
  const [editingCell, setEditingCell] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [metadata, setMetadata] = useState({});
  const [linkProduct, setLinkProduct] = useState("");

  const [loadingFetchBrief, setLoadingFetchBrief] = useState(false);
  const [loadingUpdateProductLink, setLoadingUpdateProductLink] =
    useState(false);

  const fetchBriefs = async (page = 1) => {
    setLoadingFetchBrief(true);
    const response = await rndServices.fetchBriefs({
      page,
      limit: 30,
      view: "optimize-epm",
      sorting,
      ...query,
    });
    const { data, metadata } = response;
    if (data) {
      setBriefs(
        map(data, (x, index) => {
          return {
            ...x,
            id: index + 1,
            date: moment(x.createdAt)
              .tz("Asia/Ho_Chi_Minh")
              .format("DD/MM/YYYY"),
            time: Math.floor(
              moment()
                .tz("Asia/Ho_Chi_Minh")
                .diff(moment(x.createdAt), "hours", true)
            ),
          };
        })
      );
      setPagination(metadata);
    } else {
      setBriefs([]);
    }
    setMetadata(metadata);
    setLoadingFetchBrief(false);
    setTrigger(false);
  };
  const fetchUsers = async () => {
    const { data } = await rndServices.getUsers({
      limit: -1,
    });
    setUsers(data);
    setUsersFetched(true);
  };
  const fetchDirections = async () => {
    const response = await rndServices.fetchDirections({
      page: -1,
      limit: -1,
    });
    const { data } = response;
    setDirections(data || []);
    setDirectionsFetched(true);
  };
  const handlePageChange = (page) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    if (usersFetched && directionsFetched) fetchBriefs(pagination.currentPage);
  }, [
    pagination.currentPage,
    query,
    trigger,
    sorting,
    usersFetched,
    directionsFetched,
  ]);

  useEffect(() => {
    fetchUsers();
    fetchDirections();
  }, []);

  const handleUpdateLinkProduct = async (uid) => {
    setLoadingUpdateProductLink(true);
    if (!linkProduct) {
      setLoadingUpdateProductLink(false);
      showNotification("Thất bại", "Link Product không được để trống", "red");
      return;
    }
    if (selectedSKU?.archive === STATUS.ARCHIVED) {
      showNotification(
        "Thất bại",
        "Không thể update link cho brief đã archived",
        "red"
      );
      setLoadingUpdateProductLink(false);
      return;
    }
    const urlPattern =
      /^(https?:\/\/)((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[\w-]*)?$/i;
    if (!urlPattern.test(linkProduct)) {
      showNotification("Thất bại", "Link Product không hợp lệ", "red");
      setLoadingUpdateProductLink(false);
      return;
    }
    let realStatus = null;
    switch (selectedSKU?.briefType) {
      case BRIEF_TYPES[6]:
        if (selectedSKU?.status === STATUS.OPTIMIZED_LISTING_DESIGNED) {
          realStatus = STATUS.OPTIMIZED_LISTING_EPM;
        }
        break;
      default:
        realStatus = null;
        break;
    }
    if (linkProduct) {
      const updateResponse = await rndServices.updateBriefWithView({
        uid,
        data: {
          linkProduct,
          status: STATUS.LISTED,
          ...(realStatus && { status: realStatus }),
        },
        view,
      });
      if (updateResponse) {
        showNotification(
          "Thành công",
          "Update Link Product thành công",
          "green"
        );
        await fetchBriefs(pagination.currentPage);
      }
    }
    close();
    setLoadingUpdateProductLink(false);
  };
  return (
    <>
      <Card
        className={styles.card}
        title="POD EPM OPTIMIZED TASK"
        classTitle={styles.title}
        classCardHead={cn(styles.head, { [styles.hidden]: visible })}
        head={
          <>
            <Flex
              style={{
                gap: "30px",
                padding: "10px",
                borderRadius: "10px",
                backgroundColor: "#EFF0F1",
              }}
              justify="end"
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Số card: {metadata?.totalUndoneBriefs || 0}
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Time to done: {metadata?.totalTimeToDoneAllBriefsV2Round || 0}
                {metadata?.totalTimeToDoneAllBriefsV2Round > 1
                  ? " Days "
                  : " Day "}
              </div>
            </Flex>
          </>
        }
      >
        <Grid
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "start",
          }}
        >
          <Grid.Col span={6}>
            <Flex
              style={{
                gap: "30px",
                padding: "10px",
                borderRadius: "10px",
                backgroundColor: "#EFF0F1",
              }}
              justify="start"
              align="center"
            >
              <Switch
                checked={query?.priority === 2}
                labelPosition="left"
                onChange={() => {
                  setQuery({
                    ...query,
                    priority: query?.priority === 1 ? 2 : 1,
                  });
                }}
                styles={{
                  label: {
                    fontSize: "16px",
                    fontWeight: "bold",
                  },
                }}
                label="Priority View"
              />
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Số card: {metadata?.totalUndoneBriefsWithFilter || 0}
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Time to done:{" "}
                {metadata?.totalTimeToDoneBriefsWithFilterV2Round || 0}
                {metadata?.totalTimeToDoneBriefsWithFilterV2Round > 1
                  ? " Days "
                  : " Day "}
              </div>
            </Flex>
          </Grid.Col>
        </Grid>
        {!opened && (
          <Table
            className={styles.details}
            onClose={() => setVisible(false)}
            briefs={briefs}
            query={query}
            setQuery={setQuery}
            setSelectedSKU={setSelectedSKU}
            openModal={open}
            users={users}
            setUpdateBrief={setUpdateBrief}
            updateBrief={updateBrief}
            setEditingCell={setEditingCell}
            editingCell={editingCell}
            loadingFetchBrief={loadingFetchBrief}
            setLoadingFetchBrief={setLoadingFetchBrief}
            setTrigger={setTrigger}
            setLinkProduct={setLinkProduct}
            setSorting={setSorting}
            sorting={sorting}
            metadata={metadata}
            view={"epm"}
            selectedBriefTypes={OPTIMIZED_BRIEF_TYPES}
            directions={directions}
          />
        )}
      </Card>
      <Pagination
        total={pagination.totalPages}
        page={pagination.currentPage}
        onChange={handlePageChange}
        color="pink"
        size="md"
        style={{ marginTop: "20px", marginLeft: "auto" }}
      />
      {selectedSKU && selectedSKU?.briefType === BRIEF_TYPES[0] && (
        <ProductBase
          opened={opened}
          close={close}
          selectedSKU={selectedSKU}
          linkProduct={linkProduct}
          loadingUpdateProductLink={loadingUpdateProductLink}
          setLinkProduct={setLinkProduct}
          handleUpdateLinkProduct={handleUpdateLinkProduct}
          setSelectedSKU={setSelectedSKU}
          fetchBriefs={fetchBriefs}
          view={view}
        />
      )}
      {selectedSKU && selectedSKU?.briefType === BRIEF_TYPES[10] && (
        <ProductBase
          opened={opened}
          close={close}
          selectedSKU={selectedSKU}
          linkProduct={linkProduct}
          loadingUpdateProductLink={loadingUpdateProductLink}
          setLinkProduct={setLinkProduct}
          handleUpdateLinkProduct={handleUpdateLinkProduct}
          setSelectedSKU={setSelectedSKU}
          fetchBriefs={fetchBriefs}
          view={view}
        />
      )}
      {selectedSKU && selectedSKU?.briefType === BRIEF_TYPES[1] && (
        <Clipart
          opened={opened}
          close={close}
          selectedSKU={selectedSKU}
          linkProduct={linkProduct}
          loadingUpdateProductLink={loadingUpdateProductLink}
          setLinkProduct={setLinkProduct}
          handleUpdateLinkProduct={handleUpdateLinkProduct}
          setSelectedSKU={setSelectedSKU}
          fetchBriefs={fetchBriefs}
          view={view}
        />
      )}
      {selectedSKU && selectedSKU?.briefType === BRIEF_TYPES[2] && (
        <Niche
          opened={opened}
          close={close}
          selectedSKU={selectedSKU}
          linkProduct={linkProduct}
          loadingUpdateProductLink={loadingUpdateProductLink}
          setLinkProduct={setLinkProduct}
          handleUpdateLinkProduct={handleUpdateLinkProduct}
          setSelectedSKU={setSelectedSKU}
          fetchBriefs={fetchBriefs}
          view={view}
        />
      )}
      {selectedSKU && selectedSKU?.briefType === BRIEF_TYPES[3] && (
        <NewDesign
          opened={opened}
          close={close}
          selectedSKU={selectedSKU}
          linkProduct={linkProduct}
          loadingUpdateProductLink={loadingUpdateProductLink}
          setLinkProduct={setLinkProduct}
          handleUpdateLinkProduct={handleUpdateLinkProduct}
          setSelectedSKU={setSelectedSKU}
          fetchBriefs={fetchBriefs}
          view={view}
        />
      )}
      {selectedSKU && selectedSKU?.briefType === BRIEF_TYPES[9] && (
        <NewDesign
          opened={opened}
          close={close}
          selectedSKU={selectedSKU}
          linkProduct={linkProduct}
          loadingUpdateProductLink={loadingUpdateProductLink}
          setLinkProduct={setLinkProduct}
          handleUpdateLinkProduct={handleUpdateLinkProduct}
          setSelectedSKU={setSelectedSKU}
          fetchBriefs={fetchBriefs}
          view={view}
        />
      )}
      {selectedSKU && selectedSKU?.briefType === BRIEF_TYPES[4] && (
        <ScaleDesign
          opened={opened}
          close={close}
          selectedSKU={selectedSKU}
          linkProduct={linkProduct}
          loadingUpdateProductLink={loadingUpdateProductLink}
          setLinkProduct={setLinkProduct}
          handleUpdateLinkProduct={handleUpdateLinkProduct}
          setSelectedSKU={setSelectedSKU}
          fetchBriefs={fetchBriefs}
          view={view}
        />
      )}
      {selectedSKU && selectedSKU?.briefType === BRIEF_TYPES[5] && (
        <MixMatch
          opened={opened}
          close={close}
          selectedSKU={selectedSKU}
          linkProduct={linkProduct}
          loadingUpdateProductLink={loadingUpdateProductLink}
          setLinkProduct={setLinkProduct}
          handleUpdateLinkProduct={handleUpdateLinkProduct}
          setSelectedSKU={setSelectedSKU}
          fetchBriefs={fetchBriefs}
          view={view}
        />
      )}
      {selectedSKU &&
        (selectedSKU?.briefType === BRIEF_TYPES[6] ||
          selectedSKU?.briefType === BRIEF_TYPES[7] ||
          selectedSKU?.briefType === BRIEF_TYPES[8]) &&
        opened && (
          <Optimized
            opened={opened}
            close={close}
            selectedSKU={selectedSKU}
            linkProduct={linkProduct}
            loadingUpdateProductLink={loadingUpdateProductLink}
            setLinkProduct={setLinkProduct}
            handleUpdateLinkProduct={handleUpdateLinkProduct}
            setSelectedSKU={setSelectedSKU}
            fetchBriefs={fetchBriefs}
            view={view}
          />
        )}
    </>
  );
};

const OptimizedEpmScreen = () => {
  return <OptimizedEpm view="epm" />;
};

export default OptimizedEpmScreen;
