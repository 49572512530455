import { useEffect, useState, useCallback } from "react";
import {
  InputBase,
  Combobox,
  useCombobox,
  ScrollArea,
  CloseButton,
  Input,
} from "@mantine/core";
import { filter, find, isEqual, map } from "lodash";
import { isEmpty } from "lodash";

const AppMultiSelect = ({
  options = [],
  onChange,
  defaultValue = [],
  placeholder,
  onClear,
}) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const [values, setValues] = useState([]);

  // Using a callback to memoize the handleSelectOption to avoid unnecessary re-renders
  const handleSelectOption = useCallback(
    (option) => {
      let selectedOptions = [];
      const valueOption = values?.find((it) => it?.value === option?.value);
      if (valueOption) {
        // remove it
        selectedOptions = filter(values, (o) => o.value !== valueOption.value);
        setValues(selectedOptions);
      } else {
        // add it
        selectedOptions = [...(!isEmpty(values) ? values : []), option];
        setValues(selectedOptions);
      }
      onChange?.(selectedOptions); // Only call onChange if needed
    },
    [values, onChange]
  );

  // Update values only if defaultValue has changed
  useEffect(() => {
    if (!isEmpty(options) && !isEqual(defaultValue, values)) {
      setValues(defaultValue);
    }
  }, [options, defaultValue, values]);

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(val) => {
        handleSelectOption(val);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          component="button"
          type="button"
          pointer
          rightSection={
            !isEmpty(values) ? (
              <CloseButton
                size="sm"
                onMouseDown={(event) => event.preventDefault()}
                onClick={() => {
                  setValues([]);
                  onClear?.();
                }}
                aria-label="Clear value"
              />
            ) : (
              <Combobox.Chevron />
            )
          }
          rightSectionPointerEvents={isEmpty(values) ? "none" : "all"}
          onClick={() => combobox.toggleDropdown()}
          style={{ overflow: "hidden" }}
        >
          {!isEmpty(values) ? (
            `${values[0]?.label} ${
              values.length > 1 ? `+${values.length - 1}` : ""
            }`
          ) : (
            <Input.Placeholder>{placeholder || "Pick value"}</Input.Placeholder>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown miw={200}>
        <Combobox.Options mah={400} style={{ overflowY: "auto" }}>
          <ScrollArea.Autosize type="scroll" mah={400}>
            {map(options, (option) => (
              <Combobox.Option
                value={option}
                key={option?.value}
                style={{
                  color: option?.color || "",
                }}
              >
                {find(values, (it) => it.value === option?.value) ? "✓ " : ""}
                {option.label}
              </Combobox.Option>
            ))}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default AppMultiSelect;
