import "react-loading-skeleton/dist/skeleton.css";
import { Routes, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import NewBriefs from "./screens/NewBriefs";
import DesignerScreens from "./screens/Designer";
import EPMScreens from "./screens/EPM";
import { ProductLine } from "./screens/ProductLine";
import { CreateWaitingPosts, MKTScreens } from "./screens/Marketing";
import DesignerFeedbackScreens from "./screens/DesignerFeedback";
import RootCampaign from "./screens/RootCampaign";
import Caption from "./screens/Caption";
import ManageAccounts from "./screens/ManageAccounts";
import CreatePost from "./screens/CreatePost";
import CreateCampsScreen from "./screens/CreateCamps";
import CreatedCampsScreen from "./screens/CreatedCamps";
import VideoScreens from "./screens/Video";
import Auth0LoginScreen from "./screens/Auth0Login";
import Page404 from "./screens/NotFound";
import ForbiddenPage from "./components/ForbiddenPage";
import Setting from "./screens/Setting";
import UserScreen from "./screens/Users";
import EmailVerify from "./components/VerifyEmail";
import ArtistScreen from "./screens/Artist";
import BriefProductLine from "./screens/BriefProductLine";
import ArtistTask from "./screens/ArtistTask";
import BriefProductLineTask from "./screens/ProductLineTask";
import ProductLineReadyToLaunch from "./screens/ProductLineReadyToLaunch";
import ScreenshotTask from "./screens/ScreenShotTask";
import Mockup from "./screens/Mockup";
import ComingSoon from "./screens/ComingSoon";
import DashboardSetting from "./screens/DashboardSetting";
import ProductivityDashboard from "./screens/ProductivityDashboard";
import Sellerboard from "./screens/Sellerboard";
import RankingPODShopifyProducts from "./screens/Ranking";
import PODDashboard from "./screens/PODDashboard";
import RequestVideoSample from "./screens/RequestVideoSample";
import CreatedPost from "./screens/CreatedPost";
import RequestVideoScreenPage from "./screens/RequestVideoScreen";
import AMZDesignerTask from "./screens/AMZDesignerTask";
import AMZEPMScreens from "./screens/amzEPM";
import { AMZMKTDashboard } from "./screens/AMZMKT";
import Converter from "./screens/Converter";
import { PoliticsMKTDashboard } from "./screens/PoliticsMKT";
import { PoliticsCreatedCampsDashboard } from "./screens/PoliticsCreatedCamp";
import RnDReport from "./screens/RnDReport";
import CommonReport from "./screens/CommonReport";
import VideoReport from "./screens/VideoReport";
import SampleReportTable from "./screens/SampleReport/Table";
import SampleReport from "./screens/SampleReport";
import MKTReport from "./screens/MKTReport";
import MasterReport from "./screens/MasterReport";
import { CreateSinglePost } from "./screens/Politics/SinglePost";
import CreateBatchPosts from "./screens/Politics/MultiplePost";
import PoliticsAssignCTA from "./screens/Politics/AssignCTA";
import PoliticsCreatedCampsScreen from "./screens/Politics/Camps/CreatedCamp";
import PoliticsCreateCamp from "./screens/Politics/Camps/CreateCamp";
import CreateExternalSku from "./screens/CreateExternalSku";
import DesignerReport from "./screens/DesignerReport";
import EPMReport from "./screens/EPMReport";
import OptimizeReport from "./screens/OptimizeReport";
import ScaleMKTReport from "./screens/ScaleMKTReport";
import OptimizedDesignerScreen from "./screens/Optimized/Designer";
import NewOptimizedBriefsScreen from "./screens/Optimized/RnD";
import OptimizedEpmScreen from "./screens/Optimized/EPM";
import DirectionScreen from "./screens/Direction";
import Playground from "./screens/Playground";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Page title="Board">
            <DirectionScreen />
          </Page>
        }
      />
      <Route path="/converter" element={<Converter />} />
      <Route path="/external-sku" element={<CreateExternalSku />} />
      <Route
        path="/rnd"
        element={
          <Page title="Board">
            <NewBriefs />
          </Page>
        }
      />
      <Route
        path="/rnd/product-line"
        element={
          <Page title="Campaigns dashboard">
            <ProductLine />
          </Page>
        }
      />
      <Route
        path="/rnd/report"
        element={
          <Page title="Campaigns dashboard">
            <RnDReport />
          </Page>
        }
      />
      <Route
        path="/pod/design"
        element={
          <Page title="Campaigns">
            <DesignerScreens />
          </Page>
        }
      />
      <Route
        path="/amz/design"
        element={
          <Page title="Campaigns">
            <AMZDesignerTask />
          </Page>
        }
      />
      <Route
        path="/designer/report"
        element={
          <Page title="Campaigns">
            <DesignerReport />
          </Page>
        }
      />
      <Route
        path="/epm/report"
        element={
          <Page title="Campaigns">
            <EPMReport />
          </Page>
        }
      />
      <Route
        path="/product-base/new-clipart/task"
        element={
          <Page title="Artist">
            <ArtistTask />
          </Page>
        }
      />
      <Route
        path="/product-base/new-clipart/brief"
        element={
          <Page title="Artist">
            <ArtistScreen />
          </Page>
        }
      />
      <Route
        path="/product-base/new-product-line"
        element={
          <Page title="Product Line">
            <BriefProductLine />
          </Page>
        }
      />
      <Route
        path="/product-base"
        element={
          <Page title="Product Line">
            <BriefProductLine />
          </Page>
        }
      />
      <Route
        path="/product-base/new-product-line/task"
        element={
          <Page title="Product Line">
            <BriefProductLineTask />
          </Page>
        }
      />

      <Route
        path="/product-base/mockup/ready-to-launch"
        element={
          <Page title="Product Line">
            <ProductLineReadyToLaunch />
          </Page>
        }
      />
      <Route
        path="/product-base/mockup/task"
        element={
          <Page title="Product Line">
            <Mockup />
          </Page>
        }
      />
      <Route
        path="/request-video/photography"
        element={
          <Page title="Product Line">
            <ScreenshotTask />
          </Page>
        }
      />

      <Route
        path="/designer/feedback"
        element={
          <Page title="Designer Feedback">
            <DesignerFeedbackScreens />
          </Page>
        }
      />
      <Route
        path="/video"
        element={
          <Page title="Designer Feedback">
            <VideoScreens />
          </Page>
        }
      />
      <Route
        path="/pod/epm"
        element={
          <Page title="EPM">
            <EPMScreens />
          </Page>
        }
      />
      <Route
        path="/amz/epm"
        element={
          <Page title="EPM">
            <AMZEPMScreens />
          </Page>
        }
      />
      <Route
        path="/mkt"
        element={
          <Page title="MKT">
            <MKTScreens />
          </Page>
        }
      />
      <Route
        path="/mkt/post"
        element={
          <Page title="MKT">
            <MKTScreens />
          </Page>
        }
      />
      <Route
        path="/mkt/post/create"
        element={
          <Page title="MKT">
            <CreatePost createType="single_post" />
          </Page>
        }
      />
      <Route
        path="/mkt/post/assign-cta"
        element={
          <Page title="MKT">
            <CreatedPost createType="multiple_post" />
          </Page>
        }
      />
      <Route
        path="/mkt/material/root-campaign"
        element={
          <Page title="MKT">
            <RootCampaign />
          </Page>
        }
      />
      <Route
        path="/mkt/material/caption"
        element={
          <Page title="MKT">
            <Caption />
          </Page>
        }
      />
      <Route
        path="/mkt/material/account"
        element={
          <Page title="MKT">
            <ManageAccounts />
          </Page>
        }
      />
      <Route
        path="/mkt/post"
        element={
          <Page title="MKT">
            <CreatePost />
          </Page>
        }
      />
      <Route
        path="/mkt/post/dashboard"
        element={
          <Page title="MKT">
            <CreateWaitingPosts />
          </Page>
        }
      />
      <Route
        path="/amz-mkt"
        element={
          <Page title="MKT">
            <AMZMKTDashboard />
          </Page>
        }
      />

      <Route
        path="/mkt/post"
        element={
          <Page title="MKT">
            <CreatePost />
          </Page>
        }
      />
      <Route
        path="/mkt/camp/dashboard"
        element={
          <Page title="MKT">
            <CreateCampsScreen />
          </Page>
        }
      />
      <Route
        path="/mkt/camp/created"
        element={
          <Page title="MKT">
            <CreatedCampsScreen />
          </Page>
        }
      />
      <Route
        path="/mkt/material/setting"
        element={
          <Page title="MKT">
            <Setting name="mkt-camp" />
          </Page>
        }
      />
      <Route
        path="/product-base/new-clipart/setting"
        element={
          <Page title="MKT">
            <Setting name="art" />
          </Page>
        }
      />
      <Route
        path="/video/setting"
        element={
          <Page title="MKT">
            <Setting name="design" />
          </Page>
        }
      />
      <Route
        path="/designer/setting"
        element={
          <Page title="MKT">
            <Setting name="design" />
          </Page>
        }
      />
      <Route
        path="/epm/setting"
        element={
          <Page title="MKT">
            <Setting name="epm" />
          </Page>
        }
      />
      <Route
        path="/product-base/new-product-line/setting"
        element={
          <Page title="MKT">
            <Setting name="new-product-line" />
          </Page>
        }
      />
      <Route
        path="/product-base/mockup/setting"
        element={
          <Page title="MKT">
            <Setting name="mockup" />
          </Page>
        }
      />
      <Route
        path="/users"
        element={
          <Page title="MKT">
            <UserScreen />
          </Page>
        }
      />

      <Route
        path="/rnd"
        element={
          <Page title="MKT">
            <UserScreen />
          </Page>
        }
      />
      <Route
        path="/dashboard/rechart"
        element={
          <Page title="MKT">
            <DashboardSetting />
          </Page>
        }
      />
      <Route
        path="/dashboard/default-setting"
        element={
          <Page title="MKT">
            <DashboardSetting />
          </Page>
        }
      />
      <Route
        path="/dashboard/sales"
        element={
          <Page title="MKT">
            <ProductivityDashboard />
          </Page>
        }
      />
      <Route
        path="/dashboard/amz-seller-board"
        element={
          <Page title="MKT">
            <Sellerboard />
          </Page>
        }
      />
      <Route
        path="/dashboard/pod-ranking"
        element={
          <Page title="MKT">
            <RankingPODShopifyProducts />
          </Page>
        }
      />
      <Route
        path="/dashboard/pod"
        element={
          <Page title="MKT">
            <PODDashboard />
          </Page>
        }
      />
      <Route
        path="/request-video/sample"
        element={
          <Page>
            <RequestVideoSample />
          </Page>
        }
      />
      <Route
        path="/request-video/sample/report"
        element={
          <Page>
            <SampleReport />
          </Page>
        }
      />
      <Route
        path="/mkt/report"
        element={
          <Page>
            <MKTReport />
          </Page>
        }
      />
      <Route
        path="/dashboard/report"
        element={
          <Page>
            <MasterReport />
          </Page>
        }
      />
      <Route
        path="/request-video/video/report"
        element={
          <Page>
            <VideoReport />
          </Page>
        }
      />

      <Route
        path="/request-video/video"
        element={
          <Page>
            <RequestVideoScreenPage />
          </Page>
        }
      />
      <Route path="/sign-up" element={<Auth0LoginScreen />} />
      <Route path="/sign-in" element={<Auth0LoginScreen />} />
      <Route path="*" element={<Page404 />} />
      <Route path="/forbidden" element={<ForbiddenPage />} />

      {/* POLITICS MKT ZONE */}
      <Route
        path="/politics-mkt/post/dashboard"
        element={
          <Page title="MKT">
            <CreateSinglePost />
          </Page>
        }
      />
      <Route
        path="/politics-mkt/post/create"
        element={
          <Page title="MKT">
            <CreateBatchPosts />
          </Page>
        }
      />
      <Route
        path="/politics-mkt/post/assign-cta"
        element={
          <Page title="MKT">
            <PoliticsAssignCTA />
          </Page>
        }
      />
      <Route
        path="/politics-mkt/camp/dashboard"
        element={
          <Page title="MKT">
            <PoliticsCreateCamp />
          </Page>
        }
      />
      <Route
        path="/politics-mkt/camp/created"
        element={
          <Page title="MKT">
            <PoliticsCreatedCampsScreen />
          </Page>
        }
      />
      {/* POLITICS MKT ZONE */}

      {/* OPTIMIZE MKT ZONE */}
      <Route
        path="/optimize-mkt/rnd"
        element={
          <Page>
            <NewOptimizedBriefsScreen />
          </Page>
        }
      />
      <Route
        path="/optimize-mkt/designer"
        element={
          <Page>
            <OptimizedDesignerScreen />
          </Page>
        }
      />
      <Route
        path="/optimize-mkt/epm"
        element={
          <Page>
            <OptimizedEpmScreen />
          </Page>
        }
      />
      <Route
        path="/optimize-mkt/dashboard"
        element={
          <Page>
            <PODDashboard />
          </Page>
        }
      />
      <Route
        path="/optimize-mkt/report"
        element={
          <Page>
            <OptimizeReport />
          </Page>
        }
      />
      {/* OPTIMIZE MKT ZONE */}

      {/* SCALE MKT ZONE */}
      <Route
        path="/scale-mkt/report"
        element={
          <Page>
            <ScaleMKTReport />
          </Page>
        }
      />
      {/* SCALE MKT ZONE */}

      {/* PLAYGROUND ZONE */}
      <Route
        path="/playground/kling-ai"
        element={
          <Page>
            <Playground />
          </Page>
        }
      />
      {/* PLAYGROUND ZONE */}
    </Routes>
  );
}

export default App;
